import React, { useRef } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-react-intl"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
//import HeaderTransParent from "../components/HeaderTransParent"
import HeaderColor from "../components/HeaderColor"
import { ButtonColor } from "../components/Button"
import Footer from "../components/Footer"
import { Row, Col } from "antd"
import iconPrev from "../../static/assets/images/icon-prev.svg"
import iconNext from "../../static/assets/images/icon-next.svg"
//import { ArrowRightOutlined } from "@ant-design/icons"
import { Icon } from "@iconify/react"
import arrowRight from "@iconify/icons-cil/arrow-right"
import styled from "@emotion/styled"
import Popup from "../components/Popup"

const HeroSlider = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  #hero__slider {
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
    .gatsby-image-wrapper {
      min-height: 100vh;
      max-height: 100vh;
    }
  }
  .slide {
    position: relative;
    min-height: 100vh;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(24, 24, 24, 0.21);
    }
    .contentWrapper {
      position: absolute;
      top: 35%;
      left: 0;
      transform: translateY(-50%);
      padding: 50px 30px;
      .slideTitle {
        font-family: "Kanit", sans-serif;
        font-weight: 700;
        font-size: 38px;
        line-height: 1.35;
        color: #fff;
        margin-bottom: 0;
        padding-right: 0;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      }
    }
  }
  .leftContainer {
    width: 100%;
    height: auto;
    position: relative;
    top: auto;
    left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    .text {
      width: 100%;
      font-family: "Kanit", sans-serif;
      font-weight: 400;
      font-size: 18px;
      color: #000;
      margin-bottom: 0;
      padding: 30px 50px;
      text-shadow: 0px 4px 46px rgba(0, 0, 0, 0.15);
    }
  }
  .bottomContainer {
    width: 100%;
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    .leftColumn {
      width: 50%;
      .previewImage {
        display: none;
      }
    }
    .rightColumn {
      width: 50%;
    }
    #hero__title__text,
    #hero__slide__number {
      font-family: "Kanit", sans-serif;
      font-weight: 700;
      font-size: 18px;
      color: #fff;
    }
    #hero__title__text {
      color: #181818;
      margin-bottom: 25px;
    }
    #hero__slide__number {
      color: rgba(24, 24, 24, 0.5);
      margin-bottom: 5px;
    }
    .titleContainer {
      width: 50%;
      height: 20vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 50%;
      padding: 30px 50px;
      background: #fff;
      .line {
        width: 125px;
        height: 2px;
        background: #ccc;
      }
    }
    .navContainer {
      width: 50%;
      height: 20vh;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 50%;
      background: #fff;
      .nav {
        width: 50px;
        height: 50px;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          min-width: 32px;
          width: 32px;
          min-height: 32px;
          height: 32px;
        }
      }
    }
  }
  .nextContainer {
    width: 100%;
    height: 50px;
    position: absolute;
    top: 60%;
    left: 0;
    transform: translateY(-70%);
    padding-left: 30px;
  }
  #hero__text__next {
    display: flex;
    align-items: center;
    width: 175px;
    height: 50px;
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    .anticon {
      font-size: 28px;
      margin-left: 5px;
    }
  }

  .slick-dots {
    position: absolute;
    top: 75%;
    right: 0;
    transform: translateY(-75%) rotate(270deg);
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 8px;
    padding: 0;
    margin: 0 auto;
    text-align: center;
    list-style: none;
    li {
      position: relative;
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 12px;
      padding: 0;
      cursor: pointer;
      transition: width 0.3s ease-in-out;
      .slickDotsStyle {
        height: 8px;
        width: auto;
        background-color: transparent;
        position: relative;
        .num {
          position: absolute;
          left: -20px;
          bottom: -5px;
          font-family: "Kanit", sans-serif;
          font-weight: 700;
          font-size: 12px;
          color: #181818;
        }
      }
      &.slick-active {
        height: 8px;
        width: 56px;
        pointer-events: none;
        transition: width 0.3s ease-in-out;
        .slickDotsStyle {
          height: 8px;
          width: 56px;
          transition: width 0.3s ease-in-out;
          .loading {
            height: 8px;
            width: 56px;
            animation: loading 5s ease-in;
            background-color: #fff;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
  @keyframes loading {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }

  @media only screen and (min-width: 768px) {
    .slide {
      .contentWrapper {
        top: 35%;
        left: 25%;
        transform: translateY(-40%);
        padding: 50px;
        .slideTitle {
          font-size: 48px;
        }
      }
    }
    .leftContainer {
      width: 25%;
      height: calc(100% - 20vh);
      position: absolute;
      top: 0;
      left: 0;
      .text {
        font-size: 18px;
        padding: 30px;
      }
    }
    .bottomContainer {
      display: flex;
      .leftColumn {
        width: 50%;
        position: relative;
        .previewImage {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          height: 20vh;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .rightColumn {
        width: 50%;
      }
      .titleContainer {
        width: 100%;
        margin-left: 50%;
        padding: 30px;
      }
      .navContainer {
        width: 30%;
        margin-left: 70%;
      }
    }
    .nextContainer {
      top: 65%;
      left: 25%;
      transform: translateY(-65%);
      padding-left: 50px;
    }
    .slick-dots {
      top: 50%;
      transform: translateY(-50%) rotate(270deg);
    }
  }
  @media only screen and (min-width: 992px) {
    .leftContainer {
      .text {
        padding: 30px 45px;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .slide {
      .contentWrapper {
        .slideTitle {
          font-size: 58px;
          padding-right: 15px;
        }
      }
    }
    .leftContainer {
      .text {
        font-size: 24px;
        line-height: 42px;
        padding: 30px 30px;
      }
    }
    .bottomContainer {
      .leftColumn {
        width: 50%;
      }
      .rightColumn {
        width: 50%;
      }
      .titleContainer {
        width: 50%;
        margin-left: 50%;
        padding: 30px 50px;
      }
      .navContainer {
        width: 50%;
        margin-left: 50%;
      }
    }
  }
  @media only screen and (min-width: 1440px) {
    .slide {
      .contentWrapper {
        .slideTitle {
          font-size: 68px;
          padding-right: 30px;
        }
      }
    }
    .leftContainer {
      .text {
        font-size: 20px;
      }
    }
  }
  @media only screen and (min-width: 1920px) {
    .slide {
      .contentWrapper {
        .slideTitle {
          font-size: 78px;
          padding-right: 50px;
        }
      }
    }
    .leftContainer {
      .text {
        font-size: 28px;
        line-height: 58px;
        padding: 30px 45px;
      }
    }
  }
  @media only screen and (max-width: 767px) and (orientation: landscape) {
    .slide {
      .contentWrapper {
        padding: 30px;
        .slideTitle {
          font-size: 38px;
        }
      }
    }
    .nextContainer {
      top: 55%;
      transform: translateY(-55%);
    }
  }
  @media only screen and (max-width: 320px) {
    .slide {
      .contentWrapper {
        padding: 30px;
        .slideTitle {
          font-size: 32px;
        }
      }
    }
    .nextContainer {
      top: 65%;
      transform: translateY(-65%);
    }
  }
`
const IntroContainer = styled.div`
  margin: 20px 0 80px 0;
  .imageWrapper {
    margin: 0 auto;
    text-align: center;
    .gatsby-image-wrapper {
      max-width: 100%;
    }
  }
  .introWrapper {
    font-family: "Kanit", sans-serif;
    padding-right: 0;
    h2 {
      font-weight: 400;
      font-size: 38px;
      color: #181818;
      margin-bottom: 5px;
    }
    h3 {
      font-weight: 400;
      font-size: 24px;
      color: #181818;
      margin-bottom: 15px;
    }
    .content {
      .text {
        font-weight: 400;
        font-size: 16px;
        color: #181818;
        margin-bottom: 30px;
      }
      ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        li {
          width: 100%;
          margin-bottom: 30px;
          padding-right: 0;
          border-top: 1px solid #000000;
          .icon {
            display: flex;
            align-items: center;
            position: relative;
            font-weight: 700;
            font-size: 16px;
            color: #181818;
            margin: 20px 0 15px 0;
            &:before {
              content: "";
              display: inline-block;
              width: 12px;
              min-width: 12px;
              height: 12px;
              min-height: 12px;
              margin-right: 10px;
              background: #7497b2;
              border-radius: 100%;
            }
          }
          span {
            font-weight: 400;
            font-size: 16px;
            color: #181818;
            p {
              color: #7497b2;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 576px) {
    .imageWrapper {
      .gatsby-image-wrapper {
        max-width: 80%;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    margin: 80px 0;
    .introWrapper {
      padding-right: 75px;
      .content {
        ul {
          li {
            width: 45%;
            padding-right: 15px;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 992px) {
    margin: 120px 0;
    .imageWrapper {
      margin: 0;
      text-align: left;
      .gatsby-image-wrapper {
        max-width: 100%;
      }
    }
    .introWrapper {
      padding-right: 45px;
      .content {
        ul {
          li {
            width: 100%;
            padding-right: 15px;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .imageWrapper {
      .gatsby-image-wrapper {
        max-width: 95%;
      }
    }
    .introWrapper {
      padding-right: 50px;
      .content {
        ul {
          li {
            width: 45%;
            padding-right: 15px;
          }
        }
      }
    }
  }
`
const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 49.6%;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    z-index: 1;
  }
  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(24, 24, 24, 0.21);
    z-index: 2;
  }
  .controlWrapper {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    button {
      width: 44px;
      height: 44px;
      background-color: transparent;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      appearance: none;
      border: none;
      border-radius: 100%;
      cursor: pointer;
      &.play {
        background-image: url("../../assets/images/icon-play.svg");
      }
      &.pause {
        background-image: url("../../assets/images/icon-pause.svg");
      }
    }
  }
  &:hover {
    .controlWrapper {
      display: flex;
    }
  }

  @media only screen and (min-width: 576px) {
    .controlWrapper {
      button {
        width: 50px;
        height: 50px;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .controlWrapper {
      button {
        width: 60px;
        height: 60px;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .controlWrapper {
      button {
        width: 70px;
        height: 70px;
      }
    }
  }
  @media only screen and (min-width: 1440px) {
    .controlWrapper {
      button {
        width: 160px;
        height: 160px;
      }
    }
  }
  @media only screen and (max-width: 320px) {
    .controlWrapper {
      button {
        width: 38px;
        height: 38px;
      }
    }
  }
`
const FullwidthContainer = styled.div`
  margin: 60px 0;
  font-family: "Kanit", sans-serif;
  .gb-container {
    padding-right: 15px;
  }
  h2 {
    font-weight: 400;
    font-size: 38px;
    color: #181818;
    margin-bottom: 5px;
  }
  h3 {
    font-weight: 400;
    font-size: 24px;
    color: #181818;
    margin-bottom: 15px;
  }
  .content {
    font-weight: 400;
    font-size: 16px;
    color: #181818;
  }

  @media only screen and (min-width: 576px) {
    .gb-container {
      padding-right: 15%;
    }
  }
  @media only screen and (min-width: 992px) {
    margin: 80px 0;
    .gb-container {
      padding-right: 20%;
    }
  }
  @media only screen and (min-width: 1200px) {
    .gb-container {
      padding-right: 25%;
    }
  }
`
const ZigzagSectionWrapper = styled.div`
  margin-bottom: 80px;
  font-family: "Kanit", sans-serif;
  .zigzagOdd {
    position: relative;
    margin-bottom: 50px;
    .imageWrapper {
      width: 100%;
      max-width: 100%;
      max-height: 75vh;
      overflow: hidden;
    }
    .contentWrapper {
      padding-top: 0;
      padding-bottom: 30px;
    }
  }
  .zigzagEven {
    position: relative;
    margin-bottom: 50px;
    .imageWrapper {
      width: 100%;
      max-width: 100%;
      max-height: 75vh;
      overflow: hidden;
    }
    .contentWrapper {
      padding: 40px 0 0 0;
    }
  }
  .contentWrapper {
    min-height: auto;
    .sub {
      font-weight: 300;
      font-size: 14px;
      color: #181818;
    }
    .title {
      font-weight: 400;
      font-size: 32px;
      color: #181818;
      margin-bottom: 10px;
    }
    .text {
      font-weight: 400;
      font-size: 24px;
      color: #181818;
    }
  }
  .buttonWrapper {
    margin-top: 25px;
  }

  @media only screen and (min-width: 992px) {
    margin-bottom: 120px;
    .zigzagOdd {
      margin-bottom: 80px;
      .imageWrapper {
        max-width: 50%;
        max-height: 50vh;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .gatsby-image-wrapper {
          height: 100%;
        }
      }
      .contentWrapper {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
    .zigzagEven {
      margin-bottom: 80px;
      .imageWrapper {
        max-width: 50%;
        max-height: 50vh;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        .gatsby-image-wrapper {
          height: 100%;
        }
      }
      .contentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 30px 0 0;
      }
    }
    .contentWrapper {
      min-height: 50vh;
    }
  }

  @media only screen and (min-width: 1200px) {
    margin-bottom: 150px;
    .zigzagOdd {
      margin-bottom: 100px;
      .imageWrapper {
        max-height: 55vh;
      }
    }
    .zigzagEven {
      margin-bottom: 100px;
      .imageWrapper {
        max-height: 55vh;
      }
    }
    .contentWrapper {
      min-height: 55vh;
    }
  }
`

const IndexPage = ({ data, intl }) => {
  let defaultTitle = ``
  let defaultPreview = ``
  const heroSliderObj = useRef()
  const sliderPreviewObj = useRef()
  const heroSlideNumber = useRef()
  const heroTitleText = useRef()
  const homeVideoObj = useRef()
  const locale = intl && intl.locale ? intl.locale : `th`
  const { heroSliders, pageFields, zigzagFields } = data
  const {
    seoTitle,
    seoDescription,
    slogan,
    introTitle,
    introText,
    introContent,
    introImage,
    videoFile,
    videoPoster,
    fullwidthTitle,
    fullwidthText,
    fullwidthContent,
    popupBanner,
  } = pageFields.edges[0].node
  const getIntroImage = getImage(introImage)
  const getPopupbanner = getImage(popupBanner)
  const totalSlide = heroSliders.edges ? heroSliders.edges.length : 0

  const sliderSettings = {
    customPaging: function (index) {
      const num = `0${Number(index + 1)}.`
      return (
        <div className="slickDotsStyle">
          <span className="num">{num}</span>
          <span className="loading" aria-label="loading"></span>
        </div>
      )
    },
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    fade: true,
    cssEase: "linear",
    pauseOnFocus: false,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    beforeChange: (current, next) => {
      const realIndex =
        Number(next + 1) === heroSliderObj.current.props.children.length
          ? 0
          : Number(next + 1)
      const preview =
        heroSliderObj.current.props.children[realIndex].props["data-preview"]
      sliderPreviewObj.current.src = preview
    },
    afterChange: current => {
      const numToshow = Number(current) + 1
      heroSlideNumber.current.innerHTML = "0" + numToshow + "."
      const title =
        heroSliderObj.current.props.children[current].props["data-title"]
      heroTitleText.current.innerHTML = title
    },
  }
  const handleSlidePrev = () => {
    heroSliderObj.current.slickPrev()
  }
  const handleSlideNext = () => {
    heroSliderObj.current.slickNext()
  }

  const playPauseMedia = e => {
    if (homeVideoObj.current.paused) {
      homeVideoObj.current.play()
      e.target.classList.remove("play")
      e.target.classList.add("pause")
    } else {
      homeVideoObj.current.pause()
      e.target.classList.remove("pause")
      e.target.classList.add("play")
    }
  }

  return (
    <Layout>
      <Seo lang={locale} title={seoTitle} description={seoDescription} />
      <HeaderColor />
      <HeroSlider>
        <div id="hero__slider">
          <Slider ref={heroSliderObj} {...sliderSettings}>
            {heroSliders &&
              heroSliders.edges.map((slide, index) => {
                const title = slide.node.title
                const image = getImage(slide.node.image)
                const imgUrl = slide.node.image.file.url
                if (index === 0) {
                  defaultTitle = title
                }
                if (totalSlide > 1 && index === 1) {
                  defaultPreview = slide.node.image.file.url
                }
                if (totalSlide === 1) {
                  defaultPreview = slide.node.image.file.url
                }

                return (
                  <div
                    key={index}
                    className="slide"
                    data-locale={locale}
                    data-title={title}
                    data-preview={imgUrl}
                  >
                    <GatsbyImage
                      image={image}
                      alt={`Hero slider ${index}`}
                      objectFit="cover"
                      objectPosition="75% 5%"
                    />
                    <div className="overlay"></div>
                    <div className="gb-container">
                      <div className="contentWrapper">
                        <p
                          className="slideTitle"
                          dangerouslySetInnerHTML={{ __html: title }}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
          </Slider>
        </div>
        <div className="leftContainer">
          <h1
            className="text"
            dangerouslySetInnerHTML={{
              __html: slogan,
            }}
          />
        </div>
        <div className="bottomContainer">
          <div className="leftColumn">
            <div className="previewImage">
              <img
                id="hero__preview__image"
                ref={sliderPreviewObj}
                src={defaultPreview}
                alt=""
              />
            </div>
            <div className="titleContainer">
              <span id="hero__slide__number" ref={heroSlideNumber}>
                01.
              </span>
              <p
                id="hero__title__text"
                ref={heroTitleText}
                dangerouslySetInnerHTML={{ __html: defaultTitle }}
              />
              <span className="line"></span>
            </div>
          </div>
          <div className="rightColumn">
            <div className="navContainer">
              <div
                id="hero__nav__prev"
                className="nav"
                onClick={handleSlidePrev}
              >
                <img src={iconPrev} width="32" height="32" alt="Prev slide" />
              </div>
              <div
                id="hero__nav__next"
                className="nav"
                onClick={handleSlideNext}
              >
                <img src={iconNext} width="32" height="32" alt="Next slide" />
              </div>
            </div>
          </div>
        </div>
        <div className="nextContainer">
          <div id="hero__text__next" onClick={handleSlideNext}>
            {intl.formatMessage({ id: "textNext" })}
            <Icon
              icon={arrowRight}
              style={{ marginLeft: "10px", color: "#fff", fontSize: "32px" }}
            />
          </div>
        </div>
      </HeroSlider>
      {/* <HeaderTransParent /> */}
      <IntroContainer>
        <div className="gb-container">
          <Row gutter={[80, 40]}>
            <Col className="imageWrapper" xs={24} lg={12}>
              <GatsbyImage image={getIntroImage} alt="" />
            </Col>
            <Col xs={24} lg={12}>
              <div className="introWrapper">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: introTitle,
                  }}
                />
                <h3
                  dangerouslySetInnerHTML={{
                    __html: introText,
                  }}
                />
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: introContent.introContent,
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </IntroContainer>
      <VideoContainer>
        <video
          id="home__video"
          ref={homeVideoObj}
          poster={videoPoster.file.url}
          loop
          // muted
          playsInline
        >
          <source src={videoFile.file.url} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
        <div className="overlay"></div>
        <div className="controlWrapper">
          <button
            id="controls__video"
            className="play"
            aria-label="Play & Pause"
            onClick={e => playPauseMedia(e)}
          />
        </div>
      </VideoContainer>
      <FullwidthContainer>
        <div className="gb-container">
          <Row>
            <Col span={24}>
              <h2
                dangerouslySetInnerHTML={{
                  __html: fullwidthTitle,
                }}
              />
              <h3
                dangerouslySetInnerHTML={{
                  __html: fullwidthText,
                }}
              />
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: fullwidthContent.fullwidthContent,
                }}
              />
            </Col>
          </Row>
        </div>
      </FullwidthContainer>
      <ZigzagSectionWrapper>
        {zigzagFields &&
          zigzagFields.edges.map((zigzag, index) => {
            const image = getImage(zigzag.node.image)
            if (index % 2 === 0) {
              return (
                <div key={index} className="zigzagOdd">
                  <div className="imageWrapper">
                    <GatsbyImage image={image} alt="" />
                  </div>
                  <div className="gb-container">
                    <Row gutter={[80, 40]}>
                      <Col xs={24} lg={12}></Col>
                      <Col xs={24} lg={12}>
                        <div className="contentWrapper">
                          <span className="sub">{zigzag.node.sub}</span>
                          <h4
                            className="title"
                            dangerouslySetInnerHTML={{
                              __html: zigzag.node.title,
                            }}
                          />
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: zigzag.node.text,
                            }}
                          />
                          <div className="buttonWrapper">
                            <ButtonColor
                              cta={zigzag.node.link}
                              text={intl.formatMessage({
                                id: "textReadDetails",
                              })}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              )
            } else {
              return (
                <div key={index} className="zigzagEven">
                  <div className="imageWrapper">
                    <GatsbyImage image={image} alt="" />
                  </div>
                  <div className="gb-container">
                    <Row gutter={[80, 40]} align="middle">
                      <Col xs={24} lg={12}>
                        <div className="contentWrapper">
                          <span className="sub">{zigzag.node.sub}</span>
                          <h4
                            className="title"
                            dangerouslySetInnerHTML={{
                              __html: zigzag.node.title,
                            }}
                          />
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: zigzag.node.text,
                            }}
                          />
                          <div className="buttonWrapper">
                            <ButtonColor
                              cta={zigzag.node.link}
                              text={intl.formatMessage({
                                id: "textReadDetails",
                              })}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} lg={12}></Col>
                    </Row>
                  </div>
                </div>
              )
            }
          })}
      </ZigzagSectionWrapper>
      <Popup
        ImagePopup={getPopupbanner}
      />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery($locale: String) {
    heroSliders: allContentfulHomeSliderImage(
      sort: { fields: createdAt, order: ASC }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            file {
              url
            }
          }
          title
        }
      }
    }
    pageFields: allContentfulHomePage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoDescription
          slogan
          introTitle
          introText
          introContent {
            introContent
          }
          introImage {
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          videoFile {
            file {
              url
            }
          }
          videoPoster {
            file {
              url
            }
          }
          fullwidthTitle
          fullwidthText
          fullwidthContent {
            fullwidthContent
          }
          popupBanner {
            gatsbyImageData(width: 1200, placeholder: BLURRED)
          }
        }
      }
    }
    zigzagFields: allContentfulZigzagHome(
      sort: { fields: createdAt, order: ASC }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          sub
          title
          text
          image {
            gatsbyImageData(width: 1000, placeholder: BLURRED)
          }
          link
        }
      }
    }
  }
`

export default injectIntl(IndexPage)
